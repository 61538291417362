import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  MatchCenterCompetitions,
  MatchCenterEvent,
  MatchCenterMarkets,
} from "@/types/api/matchCenter";
import styles from "@/components/MatchCenter/MatchCenter.module.scss";
import { getMatchCenterWidgetData } from "@/lib/http/matchCenter";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { MatchCenterEvents } from "@/components/MatchCenterEvents/MatchCenterEvents";
import { gaEvent } from "@/lib/gtag";
import { fpCompetitionSportsMap } from "@/constants/competitions";

export interface MatchCenterProps {
  initialCompetition: MatchCenterCompetitions;
  initialMarket: MatchCenterMarkets;
  events: MatchCenterEvent[];
}

export const MatchCenter = ({
  initialCompetition,
  initialMarket,
  events,
}: MatchCenterProps): ReactElement => {
  const [currentCompetition, setCurrentCompetition] =
    useState<MatchCenterCompetitions>(initialCompetition);
  const [currentMarket, setCurrentMarket] =
    useState<MatchCenterMarkets>(initialMarket);
  const [currentEvents, setCurrentEvents] = useState(events);
  const [locale] = useLocaleFromRouter();
  const [scrollPositionX, setScrollPositionX] = useState<number>(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  // Used to prevent extra API calls when we've scrolled to the end and there is no more data to be fetched
  const [stopFetching, setStopFetching] = useState(false);

  const allCompetitionOptions: {
    id: MatchCenterCompetitions;
    name: string;
  }[] = [
    { id: "ALL", name: "All Sports" },
    { id: "NFL", name: "NFL" },
    { id: "NCAAF", name: "NCAAF" },
    { id: "NBA", name: "NBA" },
    { id: "NCAAB", name: "NCAAB" },
    { id: "NHL", name: "NHL" },
    { id: "MLB", name: "MLB" },
  ];

  const allMarketOptions: {
    id: MatchCenterMarkets;
    name: string;
  }[] = [
    { id: "SPREAD", name: "Spread" },
    { id: "TOTAL", name: "Total" },
    { id: "ML", name: "M/L" },
  ];

  // Give the scrollable container a ref
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const lastCardRef = useRef<HTMLDivElement>(null);

  const fetchEvents = async (offset: number, limit: number) => {
    const { events } = await getMatchCenterWidgetData({
      locale,
      competition: currentCompetition,
      market: currentMarket,
      limit,
      offset,
    });

    if (events) {
      setStopFetching(events.length < 10);

      if (offset > 0) {
        setCurrentEvents((oldEvents) => [...oldEvents, ...events]);
      } else {
        setCurrentEvents(events);
      }
    }

    return events;
  };

  useEffect(() => {
    if (isInitialRender) return;

    // Fetch with new competition and scroll back
    const fetchData = async () => {
      const events = await fetchEvents(0, 10);

      // Only scroll back if true, and if we have more events that fit in the initial view
      // Stops unnecessary scroll animation
      if (events && events.length > 5) {
        const container = cardsContainerRef.current;

        if (container) {
          container.scroll({
            left: 0,
            behavior: "smooth",
          });
        }
      }
    };

    fetchData();

    // We don't want to add extra variables to the dep array so ignore this flag
    // eslint-disable-next-line
  }, [currentCompetition]);

  useEffect(() => {
    if (isInitialRender) return;

    // Fetch with new market odds for all the current events
    fetchEvents(0, currentEvents.length);

    // We don't want to add extra variables to the dep array so ignore this flag
    // eslint-disable-next-line
  }, [currentMarket]);

  useEffect(() => {
    if (lastCardRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const lastCard = entries[0];

          if (lastCard.isIntersecting && lastCardRef.current) {
            fetchEvents(currentEvents.length, 10);
            observer.unobserve(lastCardRef.current);
          }
        },
        {
          rootMargin: "100px",
        }
      );

      observer.observe(lastCardRef.current);
    }

    // We don't want to add extra variables to the dep array so ignore this
    // eslint-disable-next-line
  }, [currentEvents]);

  useEffect(() => {
    setIsScrolledToEnd(
      !!(
        cardsContainerRef.current &&
        scrollPositionX ===
          cardsContainerRef.current.scrollWidth -
            cardsContainerRef.current.clientWidth
      )
    );

    // We don't want to add extra variables to the dep array so ignore this flag
    // eslint-disable-next-line
  }, [scrollPositionX, currentEvents]);

  const scrollInDirection = (direction: "LEFT" | "RIGHT"): void => {
    const container = cardsContainerRef.current;
    const containerCardChild =
      container && (container.children[0] as HTMLDivElement);

    if (container && containerCardChild) {
      // Determine the new scroll position by adding the card width amount and the gap px to the current scroll position
      const newScrollLeft =
        direction === "LEFT"
          ? container.scrollLeft - containerCardChild.offsetWidth + 15
          : container.scrollLeft + containerCardChild.offsetWidth + 15;

      container.scroll({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (direction: "LEFT" | "RIGHT"): void => {
    isInitialRender && setIsInitialRender(false);
    scrollInDirection(direction);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    isInitialRender && setIsInitialRender(false);
    const target = event.target as HTMLDivElement;
    setScrollPositionX(target.scrollLeft);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    isInitialRender && setIsInitialRender(false);
    // We can only change one of these Select options at a time, so we can return out of this function early.
    // Took this approach because we cannot add initialMarket or initialCompetition into our useEffect deps,
    // as MSW initializes after the useEffect causing some issues with mocking
    const validCompetition = allCompetitionOptions.find(
      (option) => option.id === event.target.value
    );

    if (validCompetition) {
      const gaEventParams = {
        sport: fpCompetitionSportsMap.get(validCompetition.id) ?? "",
        competition: validCompetition.id,
        market: currentMarket,
      };

      gaEvent("odds_widget_competition_select", gaEventParams);

      setCurrentCompetition(validCompetition.id);
      setCurrentMarket(currentMarket);

      return;
    }

    const validMarket = allMarketOptions.find(
      (option) => option.id === event.target.value
    );
    if (validMarket) {
      const gaEventParams = {
        sport: fpCompetitionSportsMap.get(currentCompetition) ?? "",
        competition: currentCompetition,
        market: validMarket.id,
      };

      gaEvent("odds_widget_market_select", gaEventParams);

      setCurrentCompetition(currentCompetition);
      setCurrentMarket(validMarket.id);

      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <div className={styles.selectContainer}>
          <select
            onChange={handleSelectChange}
            defaultValue={initialCompetition}
          >
            {allCompetitionOptions.map(({ id, name }) => {
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>

        <div className={styles.selectContainer}>
          <select onChange={handleSelectChange} defaultValue={initialMarket}>
            {allMarketOptions.map(({ id, name }) => {
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {scrollPositionX !== 0 ? <div className={styles.gradient}></div> : null}

      {currentEvents.length > 0 ? (
        <div
          className={styles.fixturesContainer}
          ref={cardsContainerRef}
          onScroll={handleScroll}
        >
          <MatchCenterEvents
            currentMarket={currentMarket}
            currentEvents={currentEvents}
            lastCardRef={!stopFetching ? lastCardRef : undefined}
          />
        </div>
      ) : (
        <span className={styles.noMatchesMessage}>No matches to display</span>
      )}

      <div
        className={
          isScrolledToEnd
            ? `${styles.navButtonsContainer} ${styles.disableBoxShadow}`
            : styles.navButtonsContainer
        }
      >
        <button
          onClick={() => handleClick("LEFT")}
          disabled={scrollPositionX === 0}
        >
          {"<"}
        </button>
        <button
          onClick={() => handleClick("RIGHT")}
          disabled={
            (isScrolledToEnd && scrollPositionX !== 0) ||
            currentEvents.length === 0
          }
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
