import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";
import {
  MatchCenterCompetitions,
  MatchCenterMarkets,
  MatchCenterResponse,
} from "@/types/api/matchCenter";

interface GetMatchCenterWidgetData {
  locale: string;
  competition: MatchCenterCompetitions;
  market: MatchCenterMarkets;
  limit: number;
  offset: number;
}

export const getMatchCenterWidgetData = async ({
  locale,
  competition,
  market,
  limit,
  offset,
}: GetMatchCenterWidgetData): Promise<MatchCenterResponse> => {
  const path = "flashpicks/fixtures";

  // Default to US-NJ if the user is not from the US
  const location = locale.toUpperCase().startsWith("US-")
    ? locale.toUpperCase()
    : "US-NJ";

  const queryParams = {
    competition,
    market,
    limit,
    offset,
    location,
  };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
