import React, { ReactElement, RefObject } from "react";
import styles from "@/components/MatchCenterEventCard/MatchCenterEventCard.module.scss";
import { MatchCenterTeamRow } from "@/components/MatchCenterTeamRow/MatchCenterTeamRow";
import { fullDateToAmericanShortDateWithAMorPM } from "@/lib/date";
import { MatchCenterEvent, MatchCenterMarkets } from "@/types/api/matchCenter";
import { OddsFormats } from "@/types/odds";

interface MatchCenterEventCardProps {
  event?: MatchCenterEvent;
  activeMarket?: MatchCenterMarkets;
  oddsFormat: OddsFormats;
  lastCardRef?: RefObject<HTMLDivElement>;
}

export const MatchCenterEventCard = ({
  event,
  activeMarket,
  oddsFormat,
  lastCardRef,
}: MatchCenterEventCardProps): ReactElement | null => {
  if (!event || !activeMarket) return null;

  const awayTeam = event.contestant.find((c) => c.position === "away");
  const homeTeam = event.contestant.find((c) => c.position === "home");

  const awayTeamSelections = event.selections.find((s) =>
    activeMarket !== "TOTAL" ? s.name === awayTeam?.code : s.name === "Over"
  );
  const homeTeamSelections = event.selections.find((s) =>
    activeMarket !== "TOTAL" ? s.name === homeTeam?.code : s.name === "Under"
  );

  return (
    <div className={styles.eventCard} data-testid="eventCard" ref={lastCardRef}>
      <MatchCenterTeamRow
        event={event}
        activeMarket={activeMarket}
        teamData={awayTeam}
        teamSelection={awayTeamSelections}
        oddsFormat={oddsFormat}
      />

      <MatchCenterTeamRow
        event={event}
        activeMarket={activeMarket}
        teamData={homeTeam}
        teamSelection={homeTeamSelections}
        oddsFormat={oddsFormat}
      />

      <span className={styles.matchInfo} data-testid="eventInfo">
        <strong>{event.meta.competition.id}</strong> |{" "}
        {fullDateToAmericanShortDateWithAMorPM(event.date)}
      </span>
    </div>
  );
};
