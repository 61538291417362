import React, { ReactElement, RefObject, useContext } from "react";
import { MatchCenterEvent, MatchCenterMarkets } from "@/types/api/matchCenter";
import { MatchCenterEventCard } from "@/components/MatchCenterEventCard/MatchCenterEventCard";
import { BetSlipStateContext } from "@/contexts/betSlipContext";
import { BetSlipState } from "@/types/betslip";

interface MatchCenterEventsProps {
  currentMarket?: MatchCenterMarkets;
  currentEvents?: MatchCenterEvent[];
  lastCardRef?: RefObject<HTMLDivElement>;
}

export const MatchCenterEvents = ({
  currentMarket,
  currentEvents,
  lastCardRef,
}: MatchCenterEventsProps): ReactElement | null => {
  const { oddsFormat } = useContext(BetSlipStateContext) as BetSlipState;

  if (!currentEvents || !currentMarket) return null;

  return (
    <>
      {currentEvents.map((event, index) => (
        <MatchCenterEventCard
          event={event}
          activeMarket={currentMarket}
          key={index}
          oddsFormat={oddsFormat}
          lastCardRef={
            lastCardRef && index === currentEvents.length - 1
              ? lastCardRef
              : undefined
          }
        />
      ))}
    </>
  );
};
