import Image from "next/image";
import React, { Dispatch, ReactElement, useContext } from "react";
import styles from "@/components/MatchCenterTeamRow/MatchCenterTeamRow.module.scss";
import { fpTeamLogoUrlFromId, hideBrokenImage } from "@/lib/media";
import { gaEvent } from "@/lib/gtag";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import {
  BetSlipActions,
  BetSlipState,
  CommonAndEventIdPair,
} from "@/types/betslip";
import {
  MatchCenterEvent,
  MatchCenterEventContestant,
  MatchCenterEventSelection,
  MatchCenterMarkets,
} from "@/types/api/matchCenter";
import { OddsFormats } from "@/types/odds";
import { getFormattedOdds } from "@/lib/odds";

interface MatchCenterTeamRowProps {
  event: MatchCenterEvent;
  activeMarket: MatchCenterMarkets;
  teamData?: MatchCenterEventContestant;
  teamSelection?: MatchCenterEventSelection;
  oddsFormat: OddsFormats;
}

export const MatchCenterTeamRow = ({
  event,
  activeMarket,
  teamData,
  teamSelection,
  oddsFormat,
}: MatchCenterTeamRowProps): ReactElement | null => {
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const { activeCommonAndEventIdPairs } = useContext(
    BetSlipStateContext
  ) as BetSlipState;

  const hasButtonBeenPressed = (
    currentSelection: CommonAndEventIdPair
  ): boolean => {
    return (
      activeCommonAndEventIdPairs.findIndex(
        ({ commonID, eventId }) =>
          commonID === currentSelection.commonID &&
          eventId === currentSelection.eventId
      ) > -1
    );
  };

  const getOdds = (teamSelection?: MatchCenterEventSelection) => {
    if (!teamSelection) return undefined;

    // In this context `odds` are the american odds
    // only use `odds` if we don't have all formats
    return teamSelection.oddsDecimal && teamSelection.oddsFractional
      ? {
          odds: teamSelection.oddsFractional,
          oddsDecimal: teamSelection.oddsDecimal,
          oddsAmerican: teamSelection.odds,
        }
      : teamSelection.odds;
  };

  const odds = getOdds(teamSelection);

  return teamData ? (
    <div className={styles.teamRow} data-testid="teamRow">
      <div className={styles.teamLogoAndNameContainer}>
        <Image
          src={fpTeamLogoUrlFromId(teamData.id)}
          width={24}
          height={24}
          alt={`${teamData.location} ${teamData.name} logo`}
          onError={(event) => {
            hideBrokenImage(event.currentTarget);
          }}
        />
        <p>{teamData.code.toUpperCase()}</p>
      </div>
      {teamSelection && odds ? (
        <button
          className={styles.oddsButton}
          data-testid="oddsButton"
          onClick={() => {
            dispatch({
              type: "ADD",
              commonAndSelectionIdPair: {
                eventId: event.id,
                commonID: teamSelection.commonID,
              },
              source: "Match Centre",
            });
            gaEvent("add_to_betslip", {
              bet_event: event.description,
              source: "Match Centre",
              bet_selection: `${teamData.name} ${teamSelection.name}`,
              bet_sport: `${event.type
                .charAt(0)
                .toUpperCase()}${event.type.slice(1)}`,
              bet_competition: event.meta.competition.id,
            });
          }}
          aria-pressed={hasButtonBeenPressed({
            eventId: event.id,
            commonID: teamSelection.commonID,
          })}
        >
          <div>
            <p>{getFormattedOdds(odds, oddsFormat)}</p>
            <p>
              {activeMarket === "TOTAL" ? "o" : ""}
              {teamSelection.handicap}
            </p>
          </div>
        </button>
      ) : null}
    </div>
  ) : null;
};
